import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import handleClick from "../utils/ButtonClickFunctions";
import wipImage from "../assets/wip.jpg";

const Wip = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        src={wipImage}
        sx={{
          marginTop: "150px",
          width: { sm: "20%", xs: "60%" },
          height: "auto",
        }}
      />
      <Typography
        variant="h2"
        sx={{
          width: {
            sm: "80%",
            xs: "90%",
          },
          height: "auto",
          fontFamily: "'Quicksand', sans-serif",
          fontSize: {
            sm: "44px",
            xs: "30px",
          },
          fontWeight: "bold",
          color: "#527CA7",
          marginTop: { sm: "2%", xs: "5%" },
          textAlign: {
            xs: "center",
            sm: "center",
          },
        }}
      >
        Actualmente estamos trabajando en nuestra web.
      </Typography>
      <Typography
        sx={{
          width: {
            sm: "50%",
            xs: "90%",
          },
          height: "auto",
          fontFamily: "'Quicksand', sans-serif",
          fontSize: {
            xs: "20px",
            sm: "20px",
          },
          fontWeight: "600",
          color: "#262629",
          marginTop: "10px",
          textAlign: {
            xs: "center",
            sm: "center",
          },
        }}
      >
        Pronto estará toda la información disponible.
      </Typography>
      <Box
        sx={{
          width: { sm: "50%", xs: "90%" },
          display: "flex",
          marginTop: "50px",
          justifyContent: { xs: "center", sm: "center" },
          alignItems: { xs: "center" },
        }}
      >
        <Box
          sx={{
            width: "500px",
            height: "50px",
            backgroundColor: "#527CA7",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#375D7B",
              fontWeight: "bold",
            },
          }}
          onClick={() => handleClick("/", navigate)}
        >
          <Typography
            variant="button"
            sx={{
              color: "#FFFFFF",
              textTransform: "none",
              fontSize: "16px",
              fontFamily: "'Quicksand', sans-serif",
            }}
          >
            Volver a página de inicio
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Wip;
