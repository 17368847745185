import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Typography from "@mui/material/Typography";

const TypoMain = ({ text, color, variant, customSx }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: "500",
        color: { color },
        marginTop: "20px",
        ...customSx,
      }}
    >
      {text}
    </Typography>
  );
};

const TypoMainLink = ({ text, color, variant, path, customSx }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: "500",
        color: { color },
        marginTop: "20px",
        "&:hover": {
          cursor: "pointer",
        },
        ...customSx,
      }}
      onClick={handleClick}
    >
      {text}
    </Typography>
  );
};

const FootBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#527CA7",
        width: "100%",
        height: "300px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "300px",
        }}
      >
        <TypoMain variant="h5" color="#FFFFFF" text="Cultura a tu medida" />
        <TypoMain variant="h6" color="#262629" text="Contáctanos" />
        <Typography
          variant="h6"
          component="a"
          href="mailto:ventas@cultus-group.com"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            color: "#FFFFFF",
          }}
        >
          ventas@cultus-group.com
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <TypoMain variant="h6" color="#262629" text="Síguenos" />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "50px",
              marginTop: "20px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <a
              href="https://www.linkedin.com/company/cultus-espana"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon sx={{ scale: "1.3", color: "#FFFFFF" }} />
            </a>
          </Box>
          <TypoMainLink
            variant="h6"
            color="#FFFFFF"
            text="Legal"
            customSx={{ marginLeft: "100px" }}
            path="/legal"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          marginLeft: "100px",
        }}
      >
        <TypoMain
          variant="h5"
          color="#FFFFFF"
          text="Cómo funciona"
          customSx={{ marginTop: "0px" }}
        />
        <TypoMainLink
          variant="h7"
          color="#FFFFFF"
          text="Para partners"
          path="/partners"
        />
        <TypoMainLink
          variant="h7"
          color="#FFFFFF"
          text="Para empresas"
          path="/empresas"
        />
        <TypoMainLink
          variant="h7"
          color="#FFFFFF"
          text="Para empleados"
          path="/empleados"
        />
      </Box>
    </Box>
  );
};

export default FootBar;
