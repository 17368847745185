import React from "react";

import Box from "@mui/material/Box";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import GroupsIcon from "@mui/icons-material/Groups";
import TuneIcon from "@mui/icons-material/Tune";
import Typography from "@mui/material/Typography";
import WorkIcon from "@mui/icons-material/Work";

import app1 from "../assets/app1.png";
import Home2 from "../assets/home2.jpeg";
import LandingDialog from "../components/LandingDialog";
import MainHome1 from "../assets/home1.jpg";

const Landing = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: { sm: "350px", xs: "450px" },
          display: "flex",
          flexDirection: "column",
          background: `url(${MainHome1}) center/cover no-repeat`,
          marginTop: "40px",
          boxShadow:
            "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <Box
          className="t-home1"
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: "row",
            justifyContent: "center",
            marginLeft: "8%",
            minHeight: "400px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "20px",
                sm: "44px",
              },
              fontWeight: "bold",
              color: "#527CA7",
              marginTop: "5%",
            }}
          >
            El arte de trabajar,<br></br> la cultura de vivir
          </Typography>
          <Typography
            sx={{
              width: "29%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "14px",
                sm: "20px",
              },
              fontWeight: "600",
              color: "#527CA7",
              marginTop: "10px",
            }}
          >
            Personaliza tu tiempo libre
          </Typography>
          <Typography
            sx={{
              width: "26%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "14px",
                sm: "16px",
              },
              fontWeight: "540",
              marginTop: "10px",
              color: "#262629",
            }}
          >
            Con un simple click, Cultus te permite reservar teatros, cines,
            clases de cerámica, pintura y mucho más.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            color: "#262629",
          }}
        >
          ¿Qué es Cultus?
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: "1%",
            fontFamily: "'Quicksand', sans-serif",
            color: "#231b23",
            width: "70%",
          }}
        >
          Cultus es un{" "}
          <strong style={{ color: "#527CA7" }}>
            programa de suscripciones
          </strong>{" "}
          diseñado para empresas que desean enriquecer la vida de sus empleados
          a través de una amplia gama de actividades culturales. Las empresas
          ofrecen un novedoso{" "}
          <strong style={{ color: "#527CA7" }}>beneficio</strong>. Clases de
          pintura, música, baile, así como cines, teatros y otros espectáculos
          son algunas de las actividades dentro de la oferta de Cultus.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "80px",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            // color: "#e2e3e6",
            color: "#262629",
          }}
        >
          ¿Cómo funciona Cultus?
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: "1%",
            fontFamily: "'Quicksand', sans-serif",
            color: "#231b23",
            width: "60%",
          }}
        >
          El disfrutar de planes culturales pasa a ser una actividad compartida.
          Empresas y empleados se benefician de nuestra oferta de eventos y
          actividades.
          <br></br>
          El empleado, se da de alta en Cultus y elige la{" "}
          <strong style={{ color: "#527CA7" }}>suscripción mensual</strong> que
          más se ajuste a sus necesiadas.
          <br></br> Desde la app elige en el{" "}
          <strong style={{ color: "#527CA7" }}>marketplace</strong> que ofrece
          Cultus las actividades que más le apetecen, reserva y disfruta.
        </Typography>
      </Box>
      <LandingDialog />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4%",
          gap: "12%",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
            }}
          >
            Cultus: un beneficio de empresas
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
            }}
          >
            Las empresas ofrecen cultus a sus empleados. Las empresas realizan
            una aportación de la suscripción mensual, con esto conseguimos que
            el precio sea muy ajustado.
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: { sm: "100%", xs: "80%" },
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "600",
                fontSize: "1.325rem",
                textAlign: "center",
                color: "#262629",
              }}
            >
              Beneficios para tu empresa
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <EmojiEmotionsIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>
                Mejora la cultura y productividad
              </strong>{" "}
              <br></br>
              Fomenta el engagement, la creatividad y el bienestar de los
              empleados.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <GroupsIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>
                Atrae y retiene talento
              </strong>{" "}
              <br></br>
              Ofrece un beneficio atractivo que diferencia a la empresa de la
              competencia.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <WorkIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>Reduce el absentismo</strong>{" "}
              <br></br>
              Los empleados felices y motivados son menos propensos a faltar al
              trabajo.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <TuneIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>Es personalizable</strong>{" "}
              <br></br>
              El continuo feedback de los empleados puede impulsar que nuevos
              partners se unan a Cultus. La cartera de actividades se customiza.
            </Typography>
          </Box>

          <LandingDialog />
        </Box>

        <Box
          component="img"
          src={Home2}
          sx={{
            width: "30%",
            height: "auto",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4%",
          gap: "12%",
        }}
      >
        <Box
          component="img"
          src={app1}
          sx={{
            width: "12%",
            height: "auto",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
        <Box
          sx={{
            width: "30%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
            }}
          >
            Tú decides cómo disfrutar
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
            }}
          >
            Descargan la app, eligen un plan y disfrutan de una amplia variedad
            de actividades culturales a medida.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <CropSquareIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>
                Un catálogo extenso y diverso
              </strong>{" "}
              <br></br>
              Teatro, música, danza, exposiciones, talleres... ¡Encuentra lo que
              te apasiona y organizate como quieras!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <CropSquareIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>
                Ahorra con tus actividades
              </strong>{" "}
              <br></br>
              La empresa aporta parte del precio, lo que permite a los empleados
              disfrutar de más actividades a un menor precio.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <CropSquareIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>Comunidad</strong> <br></br>
              Conecta con otros empleados que comparten tus intereses.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: { sm: "100%", xs: "80%" },
              marginTop: "15px",
            }}
          >
            <CropSquareIcon
              sx={{
                width: { sm: "5%", xs: "14%" },
                height: "auto",
                color: "#527CA7",
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginLeft: "20px",
              }}
            >
              <strong style={{ color: "#527CA7" }}>Desarrollo personal</strong>{" "}
              <br></br>
              Aprende nuevas habilidades, amplía tus horizontes y enriquece tu
              vida cultural y de ocio.
            </Typography>
          </Box>
          <LandingDialog />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
          flexWrap: "wrap",
        }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "4%",
          gap: "10%",
        }}
      ></Box>

      <Box
        sx={{
          width: "30%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></Box>
    </Box>
  );
};

export default Landing;
