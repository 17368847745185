import React from "react";
import LandingMenuBar from "../components/LandingMenuBar";
import MobileLandingMenuBar from "../components/MobileLandingMenuBar";

const LandingLayout = ({ isMobile, children }) => {
  return (
    <>
      {isMobile ? <MobileLandingMenuBar /> : <LandingMenuBar />}
      {children}
    </>
  );
};

export default LandingLayout;
