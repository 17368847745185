import React from "react";
import Navbar from "../components/MenuBar";
import MenuBarMobile from "../components/MobileMenuBar";
import FootBar from "../components/FootBar";
import FootBarMobile from "../components/FootBarMobile";

const Layout = ({ isMobile, children }) => {
  return (
    <>
      {isMobile ? <MenuBarMobile /> : <Navbar />}
      {children}
      {isMobile ? <FootBarMobile /> : <FootBar />}
    </>
  );
};

export default Layout;
