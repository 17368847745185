import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

import CultLogo from "../assets/cult_logo.svg";

export default function MenuBarMobile() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const navigate = useNavigate();

  const links = [
    { text: "Para partners", path: "/partners" },
    { text: "Para empresas", path: "/empresas" },
    { text: "Para empleados", path: "/empleados" },
  ];

  const DrawerList = (
    <Box
      sx={{ width: "80vw", minWidth: "240px" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "center",
          alignItem: "center",
          width: "100%",
        }}
      >
        <Box
          className="box-image"
          sx={{
            position: "static",
            width: "100%",
            height: "100%",
            left: "100px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontSize: { xs: "22px" },
              fontWeight: "bold",
              textAlign: "center",
              color: "#527CA7",
              marginLeft: "6px",
            }}
          >
            cultus
          </Typography>
          <img
            src={CultLogo}
            alt="Cult Logo"
            style={{
              width: "37.9px",
              height: "auto",
              marginLeft: "10px",
              fill: "#527CA7",
              justifyContent: "center",
            }}
          />
        </Box>
      </Box>
      <List>
        {links.map((link, index) => (
          <ListItem key={link.text} disablePadding>
            <ListItemButton onClick={() => navigate(link.path)}>
              <Typography
                sx={{
                  color: "#262629",
                  fontFamily: "'Quicksand', sans-serif",
                  fontWeight: "600",
                  ml: "9px",
                }}
              >
                {link.text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "center",
          alignItem: "center",
          width: "100%",
        }}
      >
        <Box
          className="box-image"
          sx={{
            position: "static",
            width: "100%",
            height: "100%",
            left: "100px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => navigate("/")}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontSize: { xs: "22px" },
              fontWeight: "bold",
              textAlign: "center",
              color: "#527CA7",
              marginLeft: "6px",
            }}
          >
            cultus
          </Typography>
          <img
            src={CultLogo}
            alt="Cult Logo"
            style={{
              width: "37.9px",
              height: "auto",
              marginLeft: "10px",
              fill: "#527CA7",
              justifyContent: "center",
            }}
            onClick={() => navigate("/")}
          />
        </Box>
      </Box>
      <Button
        onClick={toggleDrawer(true)}
        sx={{ marginTop: "20px", marginRight: "20px" }}
      >
        <MenuIcon sx={{ color: "#527CA7" }} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)} className="WWWWWWWWWW">
        {DrawerList}
      </Drawer>
    </Box>
  );
}
