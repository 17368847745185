import * as React from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import CultLogo from "../assets/cult_logo.svg";

export default function MobileLandingMenuBar() {
  return (
    <Box sx={{ display: "flex", justifyContent: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "center",
          alignItem: "center",
          width: "100%",
        }}
      >
        <Box
          className="box-image"
          sx={{
            position: "static",
            width: "100%",
            height: "100%",
            left: "100px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontSize: { xs: "22px" },
              fontWeight: "bold",
              textAlign: "center",
              color: "#527CA7",
              marginLeft: "6px",
            }}
          >
            cultus
          </Typography>
          <img
            src={CultLogo}
            alt="Cult Logo"
            style={{
              width: "37.9px",
              height: "auto",
              marginLeft: "10px",
              fill: "#527CA7",
              justifyContent: "center",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
