import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Typography from "@mui/material/Typography";

import App3 from "../assets/app3.jpg";
import Empleados2 from "../assets/empleados2.jpg";
import Empleados3 from "../assets/empleados3.jpg";
import Empleados4 from "../assets/empleados4.jpg";
import Empleados5 from "../assets/empleados5.jpg";
import handleClick from "../utils/ButtonClickFunctions";

const Empleados = ({ isMobile }) => {
  const navigate = useNavigate();
  const generateImage = (src) => {
    if (!isMobile) {
      return (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "40%",
            marginTop: "5%",
          }}
        >
          <Box
            component="img"
            src={src}
            sx={{
              width: "100%",
              height: "auto",
              boxShadow:
                "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
            }}
          />
        </Box>
      );
    }
    return null;
  };

  const boxData = [
    {
      title: "Un catálogo extenso y diverso",
      text: "Teatro, música, danza, exposiciones, talleres... ¡Encuentra lo que te apasiona!",
      image: generateImage(Empleados3),
    },
    {
      title: "Acceso flexible y cómodo",
      text: "Reserva entradas, compra tickets y disfruta de las actividades desde cualquier lugar y dispositivo.",
      image: generateImage(Empleados4),
    },
  ];

  const boxData1 = [
    {
      icon: <LooksOneIcon />,
      text: "Comprueba que tu empresa tenga Cultus y crea una cuenta.",
    },
    {
      icon: <LooksTwoIcon />,
      text: "Elige el tipo de subscripción que más se ajuste a tus necesidades.",
    },
    {
      icon: <Looks3Icon />,
      text: "Entra en la app y disfruta de todos nuestros beneficios. ¿A qué esperas?",
    },
  ];

  const boxData2 = [
    {
      title: "Amplia variedad de actividades culturales",
      text: "Disfruta de una oferta cultural diversa y a precios accesibles.",
    },
    {
      title: "Ahorra con tus actividades",
      text: "La empresa subvenciona parte del precio, lo que permite a los empleados disfrutar de más actividades.",
    },
    {
      title: "Comunidad",
      text: "Conectan con otros empleados que comparten sus intereses.",
    },
    {
      title: "Desarrollo personal",
      text: "Aprenden nuevas habilidades, amplían sus horizontes y enriquecen su vida cultural.",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          marginTop: { sm: "80px", xs: "20px" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box
            sx={{
              width: {
                sm: "60%",
                xs: "90%",
              },
              height: "auto",
              mt: "4%",
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center" },
              alignItems: { xs: "center" },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                fontSize: {
                  sm: "44px",
                  xs: "34px",
                },
                fontWeight: "bold",
                color: "#527CA7",
                marginTop: { sm: "5%" },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Tú decides cómo disfrutar de la cultura
            </Typography>
            <Typography
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                },
                fontWeight: "600",
                color: "#527CA7",
                marginTop: "20px",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              De la mano de tu empresa, Cultus te acerca a la cultura a precios
              reducidos.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                marginTop: "20px",
                color: "#262629",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Cine, teatro, clases de cerámica, pintura, baile y mucho más con
              descuentos exclusivos.
            </Typography>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "35%",
                marginTop: "5%",
                marginLeft: "-5%",
              }}
            >
              <Box
                component="img"
                src={Empleados2}
                sx={{
                  width: "100%",
                  height: "auto",
                  marginLeft: "-5%",
                  boxShadow:
                    "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { sm: "100%", xs: "80%" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              fontSize: "1.825rem",
              textAlign: "center",
              color: "#262629",
            }}
          >
            ¿Qué ofrece Cultus?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
            gap: { sm: "16px", xs: "20px" },
            rowGap: "30px",
            width: { sm: "70%", xs: "70%" },
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "3%",
          }}
        >
          {boxData.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "30px",
                width: "100%",
                height: { sm: "350px", xs: "280px" },
                boxShadow:
                  "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
              }}
            >
              <Typography
                variant="h5"
                textAlign="center"
                sx={{ width: { sm: "70%", xs: "90%" } }}
              >
                {data.title}
              </Typography>
              {data.image}
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ width: { sm: "70%", xs: "90%" }, mt: "5%" }}
              >
                {data.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: { sm: "100%", xs: "100%" },
          height: "500px",
          alignItems: "center",
          // justifyContent: "center",
          backgroundColor: "#4882bc",
          marginTop: "10%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { sm: "end", xs: "center" },
            width: { sm: "50%", xs: "100%" },
            marginLeft: { sm: "100px" },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: { sm: "70%", xs: "100%" },
              "& .empresa": {},
              textAlign: {
                xs: "center",
                sm: "left",
              },
              color: "#FFFFFF",
              fontFamily: "'Quicksand', sans-serif",
            }}
          >
            CÓMO FUNCIONA
          </Typography>
          <Typography
            variant="h3"
            sx={{
              width: { sm: "70%", xs: "100%" },
              mt: "2%",
              "& .empresa": {},
              textAlign: {
                xs: "center",
                sm: "left",
              },
              color: "#FFFFFF",
              fontFamily: "'Quicksand', sans-serif",
            }}
          >
            Fácil y rápido
          </Typography>
          <Typography
            variant="h7"
            sx={{
              width: { sm: "70%", xs: "80%" },
              mt: "2%",
              "& .empresa": {},
              textAlign: {
                xs: "center",
                sm: "left",
              },
              color: "#FFFFFF",
              fontFamily: "'Quicksand', sans-serif",
              marginBottom: "5%",
            }}
          >
            Descarga la app de Cultus y elige tu subscripción
          </Typography>
          {boxData1.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                width: "70%",
                marginTop: "15px",
              }}
            >
              <Box sx={{ marginRight: "20px", scale: "1.3", color: "#FFFFFF" }}>
                {data.icon}
              </Box>

              <Typography
                variant="body1"
                textAlign="left"
                sx={{
                  width: { sm: "70%", xs: "90%" },
                  color: "#FFFFFF",
                  fontFamily: "'Quicksand', sans-serif",
                }}
              >
                {data.text}
              </Typography>
            </Box>
          ))}
        </Box>
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              // alignSelf: "flex-end",
              position: "absolute", // Position the image absolutely within the parent box
              marginTop: "19.3px",
              marginRight: "100px",
              right: 0,
            }}
          >
            <Box
              component="img"
              src={App3}
              sx={{
                width: "47%",
                height: "auto",
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          marginTop: { sm: "5%", xs: "10%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { sm: "100%", xs: "80%" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              fontSize: "1.825rem",
              textAlign: "center",
              color: "#262629",
            }}
          >
            Beneficios como usuario
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
            gap: { sm: "40px", xs: "20px" },
            rowGap: "30px",
            width: { sm: "60%", xs: "70%" },
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "3%",
          }}
        >
          {boxData2.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "30px",
                width: "100%",
                height: { sm: "250px", xs: "280px" },
                boxShadow:
                  "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
              }}
            >
              {data.icon}

              <Typography
                variant="h5"
                textAlign="center"
                sx={{ width: { sm: "70%", xs: "90%" }, mt: "5%" }}
              >
                {data.title}
              </Typography>

              <Typography
                variant="body1"
                textAlign="center"
                sx={{ width: { sm: "60%", xs: "70%" }, mt: "2%" }}
              >
                {data.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: { sm: "100%", xs: "100%" },
          height: "500px",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <Box
              component="img"
              src={Empleados5}
              sx={{
                width: "65%",
                height: "auto",
                borderRadius: "30px",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center" },
            width: { sm: "50%", xs: "100%" },
            // marginLeft: "100px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: { sm: "70%", xs: "100%" },
              "& .empresa": {},
              textAlign: {
                xs: "center",
                sm: "left",
              },
              fontFamily: "'Quicksand', sans-serif",
            }}
          >
            RECOMIENDA TU EMPRESA
          </Typography>
          <Typography
            variant="h3"
            sx={{
              width: { sm: "70%", xs: "80%" },
              mt: "2%",
              "& .empresa": {},
              textAlign: {
                xs: "center",
                sm: "left",
              },
              fontFamily: "'Quicksand', sans-serif",
            }}
          >
            ¿Quieres que tu empresa forme parte de Cultus?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: { sm: "70%", xs: "80%" },
              mt: "2%",
              "& .empresa": {},
              textAlign: {
                xs: "center",
                sm: "left",
              },
              fontFamily: "'Quicksand', sans-serif",
              marginBottom: "5%",
            }}
          >
            Sólo tienes que recomendar a tu empresa. Nos pondremos en contacto
            con tu departamento de RR. HH.
          </Typography>

          <Box
            sx={{
              width: "250px",
              height: "50px",
              backgroundColor: "#527CA7",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#375D7B",
                fontWeight: "bold",
              },
            }}
            onClick={() => handleClick("/recomendar", navigate)}
          >
            <Typography
              variant="button"
              sx={{
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: "18px",
                fontFamily: "'Quicksand', sans-serif",
              }}
            >
              Recomienda Cultus
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          mt: "5%",
        }}
      ></Box>
    </Box>
  );
};

export default Empleados;
