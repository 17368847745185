import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CultLogo from "../assets/cult_logo.svg";
import handleClick from "../utils/ButtonClickFunctions";

const NavbarLink = ({ text, path }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    handleClick(path, navigate);
  };

  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleLinkClick}
    >
      <Typography
        sx={{
          fontFamily: "'Quicksand', sans-serif",
          fontSize: {
            xs: "14px",
            sm: "14px",
          },
          fontWeight: "900",
          lineHeight: "16px",
          textAlign: "center",
          color: "#527CA7",
          cursor: "pointer",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const Navbar = () => {
  const links = [
    { text: "Para partners", path: "/partners" },
    { text: "Para empresas", path: "/empresas" },
    { text: "Para empleados", path: "/empleados" },
  ];

  const navigate = useNavigate();

  const handleLogoClick = () => {
    handleClick("/", navigate);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "20px",
        boxShadow:
          "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "60px",
        position: "fixed",
        backgroundColor: "#fff",
        zIndex: 1000,
      }}
    >
      <Box
        className="box-image"
        sx={{
          position: "static",
          width: "30%",
          height: "100%",
          left: "100px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "200px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={handleLogoClick}
      >
        <Typography
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontSize: {
              xs: "22px",
              sm: "32px",
            },
            fontWeight: "bold",
            textAlign: "center",
            color: "#527CA7",
            marginLeft: "6px",
          }}
        >
          cultus
        </Typography>
        <img
          src={CultLogo}
          alt="Cult Logo"
          style={{
            width: "37.9px",
            height: "auto",
            marginLeft: "10px",
            fill: "#527CA7",
            justifyContent: "center",
          }}
        />
      </Box>
      <Box
        className="box-boxes"
        sx={{
          width: "30%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "-350px",
        }}
      >
        {links.map(({ text, path }, index) => (
          <NavbarLink
            key={index}
            text={text}
            path={path}
            onClick={() => navigate(path)}
          />
        ))}
      </Box>
      <Box
        sx={{
          width: "10%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "blue",
        }}
      ></Box>
    </Box>
  );
};

export default Navbar;
