import React from "react";

import Box from "@mui/material/Box";
import BusinessIcon from "@mui/icons-material/Business";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Typography from "@mui/material/Typography";
import UsersIcon from "@mui/icons-material/PeopleAlt";

import app1 from "../assets/app1.png";
import MainHome1 from "../assets/home1.jpg";
import Home2 from "../assets/home2.jpeg";
import Home3 from "../assets/home3.jpg";

const Home = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          background: `url(${MainHome1}) center/cover no-repeat`,
          marginTop: "50px",
          boxShadow:
            "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <Box
          className="t-home1"
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: "row",
            justifyContent: "center",
            marginLeft: "8%",
            minHeight: "400px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "20px",
                sm: "44px",
              },
              fontWeight: "bold",
              color: "#527CA7",
              marginTop: "5%",
            }}
          >
            El arte de trabajar,<br></br> la cultura de vivir
          </Typography>
          <Typography
            sx={{
              width: "29%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "14px",
                sm: "20px",
              },
              fontWeight: "600",
              color: "#527CA7",
              marginTop: "10px",
            }}
          >
            Toda las actividades y espectáculos culturales al alcance de los
            empleados.
          </Typography>
          <Typography
            sx={{
              width: "26%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "14px",
                sm: "16px",
              },
              fontWeight: "540",
              marginTop: "10px",
              color: "#262629",
            }}
          >
            Con un simple click, Cultus te permite reservar teatros, cines,
            clases de cerámica, pintura y mucho más.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            color: "#262629",
          }}
        >
          ¿Qué es Cultus?
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: "1%",
            fontFamily: "'Quicksand', sans-serif",
            color: "#231b23",
            width: "70%",
          }}
        >
          Cultus es un programa de suscripciones diseñado para empresas que
          desean enriquecer la vida de sus empleados a través de una amplia gama
          de actividades culturales. Clases de pintura, música, baile, así como
          cines, teatros y otros espectáculos son algunas de las actividades
          dentro de la oferta de Cultus.
        </Typography>
      </Box>
      {/* Columns for Partners/Empresas/Users */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#527CA7",
            borderRadius: "30px",
          }}
        >
          {/* Title of section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: "7%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HandshakeIcon
              sx={{
                width: "30%",
                height: "auto",
                color: "#e2e3e6",
                marginRight: "5%",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "bold",
                color: "#e2e3e6",
                textAlign: "center",
              }}
            >
              Partners
            </Typography>
          </Box>
          {/* Detail */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "1%",
                fontFamily: "'Quicksand', sans-serif",
                color: "#e2e3e6",
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                textAlign: "center",
              }}
            >
              Conseguirás nuevos clientes y más visibilidad para tu negocio.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
        >
          {/* Title of section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: "7%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BusinessIcon
              sx={{
                width: "30%",
                height: "auto",
                color: "#262629",
                marginRight: "5%",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "bold",
                color: "#262629",
                textAlign: "center",
              }}
            >
              Empresas
            </Typography>
          </Box>
          {/* Detail */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "1%",
                fontFamily: "'Quicksand', sans-serif",
                color: "#262629",
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                textAlign: "center",
              }}
            >
              Ofrece a tus empleados una gran variedad de actividades
              culturales, consiguiendo que disfruten de este beneficio y
              reteniendo así el talento.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#527CA7",
            borderRadius: "30px",
          }}
        >
          {/* Title of section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: "7%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UsersIcon
              sx={{
                width: "25%",
                height: "auto",
                color: "#e2e3e6",
                marginRight: "5%",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "bold",
                color: "#e2e3e6",
                textAlign: "center",
              }}
            >
              Empleados
            </Typography>
          </Box>
          {/* Detail */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "1%",
                fontFamily: "'Quicksand', sans-serif",
                color: "#e2e3e6",
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                textAlign: "center",
              }}
            >
              Acceso a un gran número de actividades culturales a un precio
              reducido gracias al apoyo de tu empresa.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "80px",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            // color: "#e2e3e6",
            color: "#262629",
          }}
        >
          ¿Cómo funciona Cultus?
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: "1%",
            fontFamily: "'Quicksand', sans-serif",
            color: "#231b23",
            width: "60%",
            textAlign: "center",
          }}
        >
          El disfrutar de planes culturales pasa a ser una actividad compartida.
          Empresas y empleados se benefician de nuestra oferta de eventos y
          actividades.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4%",
          gap: "12%",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
            }}
          >
            Cultus: un beneficio de empresas
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
            }}
          >
            Las empresas ofrecen cultus a sus empleados. Las empresas pagan una
            parte de la suscripción mensual, con esto conseguimos que el precio
            sea muy ajustado.
          </Typography>
          <Typography
            sx={{
              marginTop: "30px",
              fontFamily: "'Quicksand', sans-serif",
              color: "#537ca8",
              fontWeight: "400",
              "&:hover": {
                fontWeight: "900",
              },
              width: "100%",
            }}
          >
            Más detalle de cómo funciona para empresas
          </Typography>
        </Box>
        <Box
          component="img"
          src={Home2}
          sx={{
            width: "20%",
            height: "auto",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4%",
          gap: "12%",
        }}
      >
        <Box
          component="img"
          src={app1}
          sx={{
            width: "12%",
            height: "auto",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
        <Box
          sx={{
            width: "30%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
            }}
          >
            Los empleados usan Cultus
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
            }}
          >
            Descargan la app, eligen un plan y disfrutan de una amplia variedad
            de actividades culturales a medida.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4%",
          gap: "10%",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
            }}
          >
            Cultus, más que una plataforma, un movimiento.
          </Typography>
          <Typography
            sx={{
              marginTop: "1%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
            }}
          >
            Con Cultus, los empleados mejoran su bienestar y se sienten parte de
            una comunidad. Los equipos están más felices. Con un precio muy
            competitivo. Un gran beneficio para todos los implicados.
          </Typography>
        </Box>
        <Box
          component="img"
          src={Home3}
          sx={{
            width: "28%",
            height: "auto",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          width: "30%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></Box>
    </Box>
  );
};

export default Home;
