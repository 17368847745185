import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function LandingDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: { sm: "20px", xs: "20px" },
      }}
    >
      <Box
        sx={{
          width: "150px",
          height: "50px",
          backgroundColor: "#4882bc",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#375D7B",
            fontWeight: "bold",
          },
        }}
      >
        <Button
          onClick={handleClickOpen}
          sx={{
            color: "#FFFFFF",
            textTransform: "none",
            fontSize: "16px",
            fontFamily: "'Quicksand', sans-serif",
          }}
        >
          Me interesa
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textTransform: "none",
            fontSize: "16px",
            fontFamily: "'Quicksand', sans-serif",
          }}
        >
          {"¡Síguenos!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            En redes sociales podrás encontrar <br></br>más información sobre
            nuestros avances.
          </DialogContentText>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <a
            href="https://www.linkedin.com/company/cultus-espana"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon
              sx={{ scale: { sm: "1.3", xs: "1.6" }, color: "#527CA7" }}
            />
          </a>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "20px",
            backgroundColor: "white",
          }}
        ></Box>
      </Dialog>
    </Box>
  );
}
