import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import GroupsIcon from "@mui/icons-material/Groups";
import TuneIcon from "@mui/icons-material/Tune";
import Typography from "@mui/material/Typography";
import WorkIcon from "@mui/icons-material/Work";

import Empleados1 from "../assets/empleados1.jpg";
import EmpresasDivisionPago from "../assets/empresa_division_pago.jpg";
import handleClick from "../utils/ButtonClickFunctions";

const Empresas = ({ isMobile }) => {
  const navigate = useNavigate();
  const boxData = [
    {
      title: "Mejora la cultura y productividad",
      text: "Fomenta el engagement, la creatividad y el bienestar de los empleados.",
      icon: (
        <EmojiEmotionsIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
    {
      title: "Atrae y retiene talento",
      text: "Ofrece un beneficio atractivo que diferencia a la empresa de la competencia.",
      icon: (
        <GroupsIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
    {
      title: "Reduce el absentismo",
      text: "Los empleados felices y motivados son menos propensos a faltar al trabajo.",
      icon: (
        <WorkIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
    {
      title: "Es personalizable",
      text: "El continuo feedback de los empleados puede impulsar que nuevos partners se unan a Cultus. La cartera de actividades se customiza.",
      icon: (
        <TuneIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          marginTop: { sm: "80px", xs: "20px" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box
            sx={{
              width: {
                sm: "60%",
                xs: "90%",
              },
              height: "auto",
              mt: "4%",
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center" },
              alignItems: { xs: "center" },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                fontSize: {
                  sm: "44px",
                  xs: "34px",
                },
                fontWeight: "bold",
                color: "#527CA7",
                marginTop: { sm: "5%" },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              La felicidad de tus empleados empieza con la cultura.
            </Typography>
            <Typography
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                },
                fontWeight: "600",
                color: "#527CA7",
                marginTop: "20px",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Cultus transforma tu empresa y a tu gente ofreciéndo a tus
              empleados una gran oferta cultural.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                marginTop: "20px",
                color: "#262629",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Observarás cómo la felicidad, el bienestar y el trabajo en equipo
              florecen, impulsando resultados tangibles: mayor productividad,
              retención del talento y una mejor imagen de marca.
            </Typography>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "35%",
                marginTop: "5%",
                marginLeft: "-5%",
              }}
            >
              <Box
                component="img"
                src={Empleados1}
                sx={{
                  width: "100%",
                  height: "auto",
                  boxShadow:
                    "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { sm: "100%", xs: "80%" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              fontSize: "1.825rem",
              textAlign: "center",
              color: "#262629",
            }}
          >
            Beneficios para tu empresa
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
            gap: { sm: "16px", xs: "20px" },
            rowGap: "30px",
            width: { sm: "70%", xs: "70%" },
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "3%",
          }}
        >
          {boxData.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "30px",
                width: "100%",
                height: { sm: "250px", xs: "280px" },
                boxShadow:
                  "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
              }}
            >
              {data.icon}

              <Typography
                variant="h5"
                textAlign="center"
                sx={{ width: { sm: "70%", xs: "90%" }, mt: "5%" }}
              >
                {data.title}
              </Typography>

              <Typography
                variant="body1"
                textAlign="center"
                sx={{ width: { sm: "60%", xs: "70%" }, mt: "2%" }}
              >
                {data.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: { sm: "100%", xs: "100%" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            fontSize: "1.825rem",
            textAlign: "center",
            color: "#262629",
            mt: "5%",
          }}
        >
          ¿Cómo funciona?
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "column", xs: "column" },
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mt: "-2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { sm: "70%", xs: "90%" },
                mt: "10%",
                "& .empresa": {
                  color: "#527CA7",
                },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
                fontFamily: "'Quicksand', sans-serif",
              }}
            >
              Compromiso entre <span style={{ color: "#527CA7" }}>empresa</span>{" "}
              y empleado
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: {
                  sm: "70%",
                  xs: "80%",
                },
                mt: "4%",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              La empresa financia al empleado una parte del bono cultural. Esta
              cuota dependerá del plan elegido por el empleado. <br></br> Por
              cada empleado que quiera hacer uso de Cultus la empresa paga una
              cuota mensual.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: { sm: "50%", xs: "80%" },
                marginTop: "5%",
              }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "50px",
                  backgroundColor: "#527CA7",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#375D7B",
                    fontWeight: "bold",
                  },
                }}
                mt={2}
                mb={2}
                onClick={() => handleClick("/programas", navigate)}
              >
                <Typography
                  variant="button"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    fontSize: "18px",
                    fontFamily: "'Quicksand', sans-serif",
                  }}
                >
                  Planes de Cultus
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isMobile && (
            <Box
              component="img"
              src={EmpresasDivisionPago}
              sx={{
                width: "20%",
                height: "auto",
                mt: "3%",
                ml: "8px%",
                boxShadow:
                  "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          mt: "5%",
        }}
      ></Box>
    </Box>
  );
};

export default Empresas;
